<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col cols="12" sm="9">
          <h1>ログ</h1>
          <p class="caption">
            この画面ではセンサのログデータを出力することができます
          </p>
          <dl>
            <dt>【ログ出力方法】</dt>
            <dd>
              <ol class="caption">
                <li>右上の「新規登録」を押す</li>
                <li>
                  ログを出力したいターゲットタイプ、センサ、期間を選択し「保存」ボタンを押す
                </li>
                <li>
                  一覧画面に戻ってきた直後はダウンロードできないので、数秒待ってから右側の更新ボタン<v-icon>mdi-reload</v-icon>を押す
                </li>
                <li>
                  一覧のダウンロード欄のマーク<v-icon>mdi-download</v-icon>を押すとCSVでログデータがダウンロードできます
                </li>
              </ol>
            </dd>
          </dl>
        </v-col>
        <v-col cols="12" sm="3" class="text-right">
          <v-btn :color="mainColor" dark @click.stop="doCreate">
            新規登録
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <v-select
            v-if="isAdminGroup"
            v-model="searchByGroup"
            label="グループで絞り込み"
            :items="groupNames"
            item-text="name"
            item-value="id"
            style="top: 10px"
            clearable
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn icon @click="doLoad(false)">
            <v-progress-circular
              v-show="loading"
              :indeterminate="loading"
            ></v-progress-circular>
            <v-icon v-show="!loading">mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :loading="loading">
          <template v-slot:[`item.group_id`]="{ item }">
            {{ displayGroupName(item.group_id) }}
          </template>
          <template v-slot:[`item.device_ids`]="{ item }">
            <v-icon small @click="showDeviceList(item.device_ids)">
              mdi-remote
            </v-icon>
          </template>
          <template v-slot:[`item.target_type`]="{ item }">
            {{ displayTargetType(item.target_type) }}
          </template>
          <template v-slot:[`item.target_period`]="{ item }">
            {{ item.target_period_start | moment }} ~<br />
            {{ item.target_period_end | moment }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ displayStatus(item.status) }}
          </template>
          <template v-slot:[`item.download`]="{ item }">
            <v-icon
              small
              :disabled="downloadDisabled(item.status)"
              @click="doDonwload(item)"
            >
              mdi-download
            </v-icon>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small @click="doDelete(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            現在登録されたログはありません。
          </template>
        </v-data-table>
      </v-card>
      <log-editor :dialog="dialog" @close="close"></log-editor>
      <device-list
        :dialog="deviceListDialog"
        :deviceList="selectDeviceList"
        @close="close"
      ></device-list>
    </v-container>
  </v-main>
</template>

<script>
import { saveAs } from 'file-saver'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import {
  MAIN_COLOR,
  LOG_EXPORT_TARGET_TYPE_LIST,
  LOG_EXPORT_STATUS_LIST,
  LOG_EXPORT_STATUS_COMPLETED
} from '@/constants'
import {
  getLogExports,
  getLogExport,
  deleteLogExport,
  getGroups,
  getDevices
} from '@/api'
import LogEditor from '@/components/log-editor'
import DeviceList from '@/components/device-list'

export default {
  name: 'LogIndex',
  components: {
    LogEditor,
    DeviceList
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      searchByGroup: '',
      dialog: false,
      deviceListDialog: false,
      headers: [
        { text: 'グループ', value: 'group_id', sortable: true },
        {
          text: 'センサ',
          value: 'device_ids',
          align: 'center',
          sortable: true
        },
        {
          text: 'ターゲットタイプ',
          value: 'target_type',
          sortable: true
        },
        { text: '対象期間', value: 'target_period', sortable: false },
        {
          text: 'ステータス',
          value: 'status',
          sortable: true
        },
        {
          text: 'ダウンロード',
          value: 'download',
          align: 'center',
          sortable: false
        },
        {
          text: '削除',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ],
      logExportStatusCompleted: LOG_EXPORT_STATUS_COMPLETED,
      selectDeviceList: []
    }
  },
  computed: {
    ...mapGetters(['logExports', 'groupNames', 'deviceNames', 'isAdminGroup']),
    items() {
      if (!this.searchByGroup) {
        return this.logExports
      }
      return this.logExports.filter(log => log.group_id === this.searchByGroup)
    }
  },
  created() {
    this.doLoad(true)
  },
  methods: {
    ...mapActions([
      'setLogExports',
      'deleteLogExport',
      'setFullscreenLoading',
      'setGroups',
      'setDevices'
    ]),
    async doLoad(storeCheck) {
      if (this.loading) {
        return
      }
      this.loading = true
      Promise.all([
        this.initLogExports(storeCheck),
        this.initGroups(storeCheck),
        this.initDevices(storeCheck)
      ]).then(() => {
        this.loading = false
      })
    },
    async initLogExports(storeCheck) {
      let res
      try {
        res = await getLogExports()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setLogExports(res.log_exports)
    },
    async initGroups(storeCheck) {
      let res
      try {
        res = await getGroups()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setGroups(res.groups)
    },
    async initDevices(storeCheck) {
      let res
      try {
        res = await getDevices()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setDevices(res.devices)
    },
    doCreate() {
      this.dialog = true
    },
    async doDelete(item) {
      if (!confirm(`削除してもよろしいですか？`)) {
        return
      }

      this.setFullscreenLoading(true)
      try {
        await deleteLogExport(item)
        this.deleteLogExport(item)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.setFullscreenLoading(false)
    },
    close() {
      this.dialog = false
      this.deviceListDialog = false
    },
    async doDonwload(item) {
      this.setFullscreenLoading(true)
      let res
      try {
        res = await getLogExport(item)
        saveAs(res.download_url, 'text/csv')
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.setFullscreenLoading(false)
      return
    },
    downloadDisabled(status) {
      return status !== this.logExportStatusCompleted
    },
    showDeviceList(deviceIds) {
      this.selectDeviceList = []
      deviceIds.forEach(id => {
        const device = this.deviceNames.find(d => d.id == id)
        if (!device) {
          return
        }
        this.selectDeviceList.push(device)
      })
      this.deviceListDialog = true
    },
    displayGroupName(id) {
      const group = this.groupNames.find(g => g.id == id)
      if (!group) {
        return ''
      }
      return group.name
    },
    displayTargetType(targetType) {
      const name = LOG_EXPORT_TARGET_TYPE_LIST.find(
        log => log.value === targetType
      )
      return name === undefined ? '' : name.label
    },
    displayStatus(status) {
      const name = LOG_EXPORT_STATUS_LIST.find(log => log.value === status)
      return name === undefined ? '' : name.label
    }
  },
  filters: {
    moment(time) {
      return moment(time).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>
