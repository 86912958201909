<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">センサ</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="elevation-0"
                :headers="headers"
                :items="deviceList"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeviceList',
  props: {
    dialog: Boolean,
    deviceList: Array
  },
  data() {
    return {
      headers: [
        { text: 'センサID', value: 'id' },
        { text: 'センサ名', value: 'name' }
      ]
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
