<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="920"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card v-if="loading" min-width="320">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="grey"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">新規登録</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col col="12">
                <p class="caption text--secondary">
                  保存するとログファイルの作成を開始します。<br />
                  ファイルの作成が完了すると一覧画面からダウンロードできるようになります。
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="item.target_type"
                  label="ターゲットタイプ"
                  :items="logExportTargetTypeList"
                  item-text="label"
                  item-value="value"
                  :rules="rules.required"
                ></v-select>
                <p class="caption text--secondary">
                  ターゲットタイプ：アラート＝センサが検知したログを出力します。<br />
                  ターゲットタイプ：通知＝センサが検知してLINE通知、メール通知を送信したログを出力します。<br />
                  （通知抑制などLINE通知、メール通知を飛ばしていないものは上がってきません。）<br />
                  ターゲットタイプ：自己診断異常＝センサの自己診断機能により、異常が検出されたログ・異常から復帰したログを出力します。
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  ref="targetSelect"
                  v-model="item.device_ids"
                  :items="targetItem"
                  label="センサ"
                  item-text="name"
                  item-value="id"
                  :rules="rules.required"
                  :menu-props="{ maxHeight: 500 }"
                  multiple
                  hint="選択が終わればリスト最下部の「OK」ボタンを押してください。"
                  persistent-hint
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-text-field
                        v-model="targetSearch"
                        label="検索"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item v-if="isAdminGroup">
                      <v-select
                        v-model="targetSerachByGroup"
                        label="グループで絞り込み"
                        :items="groupNames"
                        item-text="name"
                        item-value="id"
                        clearable
                      ></v-select>
                    </v-list-item>
                    <v-list-item>
                      <v-radio-group v-model="targetSort" label="並び替え" row>
                        <v-radio label="昇順" value="asc"></v-radio>
                        <v-radio label="降順" value="desc"></v-radio>
                      </v-radio-group>
                    </v-list-item>
                    <v-list-item ripple>
                      <v-list-item-action @click="toggleTargetSelect">
                        <v-icon
                          :color="
                            item.device_ids.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ targetSelectIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content @click="toggleTargetSelect">
                        <v-list-item-title>
                          全て選択
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-row>
                      <v-col class="d-flex flex-row-reverse mr-5">
                        <v-btn depressed @click="closeTargetSelect">OK</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4 class="text--secondary">
                  対象期間
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.target_period_start"
                  label="対象期間 - 開始"
                  :rules="rules.required"
                  type="datetime-local"
                  hint="直接日時を入力していただくか、右側のカレンダーマークから日時を選択できます。"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.target_period_end"
                  label="対象期間 - 終了"
                  :rules="rules.required"
                  type="datetime-local"
                  hint="直接日時を入力していただくか、右側のカレンダーマークから日時を選択できます。"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn :color="mainColor" depressed dark @click="save">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { postLogExport } from '@/api'
import { MAIN_COLOR, LOG_EXPORT_TARGET_TYPE_LIST } from '@/constants'

export default {
  name: 'LogEditor',
  props: {
    dialog: Boolean
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      logExportTargetTypeList: LOG_EXPORT_TARGET_TYPE_LIST,
      loading: false,
      valid: true,
      rules: {
        required: [v => !!v || '必須入力です']
      },
      item: {
        group_id: '',
        device_ids: '',
        target_type: '',
        target_period_start: '',
        target_period_end: ''
      },
      targetSearch: '',
      targetSerachByGroup: '',
      targetSort: 'asc'
    }
  },
  computed: {
    ...mapGetters([
      'deviceNames',
      'deviceNamesByGroup',
      'groupNames',
      'isAdminGroup',
      'accountGroupId'
    ]),
    targetItem() {
      let items = []
      if (this.isAdminGroup) {
        items = this.deviceNames.filter((item, index) => index !== 0)
      } else {
        items = this.deviceNamesByGroup(this.accountGroupId).filter(
          (item, index) => index !== 0
        )
      }
      if (this.targetSearch) {
        items = items.filter(item => {
          return item.name.includes(this.targetSearch)
        })
      }
      if (this.isAdminGroup && this.targetSerachByGroup) {
        items = items.filter(item => {
          return item.group === this.targetSerachByGroup
        })
      }
      return this.targetSort === 'asc'
        ? items.sort(this.nameSortAsc)
        : items.sort(this.nameSortDesc)
    },
    targetSelectIcon() {
      if (this.isAllSelected) {
        return 'mdi-close-box'
      }
      if (this.item.device_ids.length > 0 && !this.isAllSelected) {
        return 'mdi-minus-box'
      }
      return 'mdi-checkbox-blank-outline'
    },
    isAllSelected() {
      return this.item.device_ids.length === this.targetItem.length
    }
  },
  watch: {
    async dialog(openend) {
      this.initItem()
    }
  },
  methods: {
    ...mapActions(['addLogExport', 'updateLogExport', 'setFullscreenLoading']),
    initItem() {
      Object.assign(this.item, {
        group_id: '',
        device_ids: '',
        target_type: '',
        target_period_start: '',
        target_period_end: ''
      })
    },
    close() {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetValidation()
      }
      this.initItem()
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      const registerItem = {
        group_id: this.editGroupId,
        device_ids: this.item.device_ids,
        target_type: this.item.target_type,
        target_period_start: moment(this.item.target_period_start).format(
          'YYYY-MM-DDTHH:mm'
        ),
        target_period_end: moment(this.item.target_period_end).format(
          'YYYY-MM-DDTHH:mm'
        )
      }
      try {
        const res = await postLogExport(registerItem)
        this.addLogExport(res.log_export)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.close()
      this.setFullscreenLoading(false)
    },
    toggleTargetSelect() {
      if (this.isAllSelected) {
        this.resetTarget()
      } else {
        this.item.device_ids = []
        this.targetItem.forEach(item => this.item.device_ids.push(item.id))
      }
    },
    closeTargetSelect() {
      this.$refs.targetSelect.hasColor = false
      this.$refs.targetSelect.isFocused = false
      this.$refs.targetSelect.isMenuActive = false
      this.resetTargetSearch()
    },
    resetTarget() {
      this.item.device_ids = []
      this.resetTargetSearch()
    },
    resetTargetSearch() {
      this.targetSerach = ''
      this.targetSerachByGroup = ''
      this.targetSort = 'asc'
    },
    nameSortAsc(a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    },
    nameSortDesc(a, b) {
      if (a.name > b.name) {
        return -1
      }
      if (a.name < b.name) {
        return 1
      }
      return 0
    }
  }
}
</script>
